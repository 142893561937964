<template>
  <div class="container">
    <h1>综合管理</h1>
    <div class="date-picker-area">
      <span class="btn-prev" @click="clickPrevDate">◀︎</span>
      <datetime
        type="date"
        v-model="startDate"
        placeholder="開始日期"
        zone="Asia/Shanghai"
        value-zone="Asia/Shanghai"
      />
      <span>~</span>
      <datetime type="date" v-model="endDate" placeholder="結束日期" zone="Asia/Shanghai" value-zone="Asia/Shanghai" />
      <span class="btn-next" @click="clickNextDate">▶</span>
    </div>
    <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size" class="mt-4"></pulse-loader>
    <ul class="countries">
      <li :key="item.id" v-for="item in providers">
        <img :src="getFlagImage(item.providerId)" />
        {{ item.totalCount | numberFormat }}
      </li>
      <li>총: {{ totalCount | numberFormat }}</li>
    </ul>
    <table class="border-table mt-4" v-if="distributors.length > 0">
      <thead>
        <tr>
          <td width="120px">日期</td>
          <td width="120px">账号</td>
          <td>发送条数</td>
          <td>成功条数</td>
          <td>失败条数</td>
          <td>等待条数</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in distributors">
          <td>
            {{ item.date | dateShortFormat }}
          </td>
          <td>
            {{ item.email }}
          </td>
          <td>
            {{ item.totalCount | numberFormat }}
          </td>
          <td>
            {{ item.successCount | numberFormat }}
          </td>
          <td>
            {{ item.failedCount | numberFormat }}
          </td>
          <td>
            {{ item.waitCount | numberFormat }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>합계</td>
          <td></td>
          <td>{{ totalCount2 | numberFormat }}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import SaleService from '@/services/SaleService'
import { Datetime } from 'vue-datetime'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'
import { Settings as LuxonSettings } from 'luxon'

LuxonSettings.defaultLocale = 'zh-cn'

export default {
  name: 'Distributor',
  components: {
    Datetime,
    PulseLoader,
  },
  created() {},
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')

    this.getInfo()
  },
  data() {
    return {
      isLoading: false,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      startDate: moment().tz('Asia/Shanghai').format(),
      endDate: moment().tz('Asia/Shanghai').format(),
      selectedUserId: null,
      distributors: [],
      providers: [],
      totalCount: 0,
      totalCount2: 0,
    }
  },
  methods: {
    async getInfo() {
      if (!this.startDate || !this.endDate) {
        return
      }

      if (this.isLoading) {
        return
      }

      this.distributors = []
      this.providers = []
      this.isLoading = true

      var data = {
        startDate: this.startDate.substring(0, 10),
        endDate: this.endDate.substring(0, 10),
      }

      const response = await SaleService.distributorList(data)
      this.isLoading = false
      if (response.status == 200) {
        this.distributors = response.data.distributors
        this.providers = response.data.providers
      }

      var totalCount = 0
      for (const item of this.providers) {
        totalCount += parseInt(item.totalCount)
      }
      this.totalCount = totalCount

      totalCount = 0
      for (const item of this.distributors) {
        totalCount += parseInt(item.totalCount)
      }
      this.totalCount2 = totalCount
    },
    clickPrevDate() {
      this.startDate = moment(this.startDate).add(-1, 'days').format()
      this.endDate = moment(this.endDate).add(-1, 'days').format()
    },
    clickNextDate() {
      this.startDate = moment(this.startDate).add(1, 'days').format()
      this.endDate = moment(this.endDate).add(1, 'days').format()
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    startDate() {
      this.getInfo()
    },
    endDate() {
      this.getInfo()
    },
  },
}
</script>
<style scoped lang="scss">
.date-picker-area {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-prev,
  .btn-next {
    cursor: pointer;
  }

  span {
    margin: 0 10px;
  }
}

ul.countries {
  margin: 30px 0 0;
  overflow: hidden;
  padding: 10px;
  border: 1px solid #333;

  > li {
    float: left;
    margin: 5px;
    line-height: 40px;
    padding: 0 10px;
    height: 40px;
    border: 1px solid #f1f2f4;
    border-radius: 3px;

    img {
      margin-right: 1px;
      width: 30px;
    }
  }
}
</style>
